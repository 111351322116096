import React from 'react';
import Layout from 'components/layout';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Images
import image01 from 'images/gallery/01.jpg';
import image02 from 'images/gallery/02.jpg';
import image03 from 'images/gallery/03.jpg';
import image04 from 'images/gallery/04.jpg';
import image05 from 'images/gallery/05.jpg';
import image06 from 'images/gallery/06.jpg';
const images = [image01, image02, image03, image04, image05, image06];

const GalleryPage = () => {
    const settings = {
        dots: true,
        autoplay: false,
        infinite: true,
        autoplaySpeed: 5000,
        speed: 1800,
        centerMode: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    centerPadding: 0,
                },
            },
        ],
    };

    return (
        <Layout>
            <div className="fluid-container page-container gallery-container">
                <div className="main-gallery">
                    <Slider {...settings}>
                        {images.map((i) => (
                            <img
                                key={`gallery-image--${i}`}
                                src={i}
                                alt="haircut one-hundred shop photo"
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </Layout>
    );
};

export default GalleryPage;
